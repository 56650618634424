import styled from 'styled-components';
import { DESKTOP_WIDTH, PRIMARY_COLOR } from '../../constants';

interface I {
  setShowFirstTimeModal: (show: boolean) => void;
  navigateToContentPolicy: () => void;
  navigateToDisputeResolution: () => void;
}

const Footer = ({
  setShowFirstTimeModal,
  navigateToContentPolicy,
  navigateToDisputeResolution,
}: I) => {
  const baseUrl = window.location.origin;
  return (
    <Wrapper>
      <SupportEmail>
        Need help? Email:{' '}
        <span>
          <u>support@onlyfakes.app</u>
        </span>
      </SupportEmail>
      <TermsAndService onClick={() => setShowFirstTimeModal(true)}>
        Terms of Service & Privacy Policy
      </TermsAndService>
      <TermsAndService onClick={() => navigateToContentPolicy()}>
        Content Policy
      </TermsAndService>

      <TermsAndService onClick={() => navigateToDisputeResolution()}>
        Dispute Resolution
      </TermsAndService>

      <NiceLink href={`${baseUrl}/blog`} target="_blank">
        <span>Blog</span>
      </NiceLink>

      <NiceLink href="https://sextingai.net" target="_blank">
        <span>Free AI Sexting app</span>
      </NiceLink>
      <Copyright>
        © 2024 OnlyFakes app
        <br />
        All rights reserved
        <br />
      </Copyright>
    </Wrapper>
  );
};

export default Footer;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
  justify-content: center;
  text-align: center;
  padding-bottom: 16px;
  font-size: 12px;
  @media (max-width: ${DESKTOP_WIDTH}px) {
    padding-bottom: 100px;
  }
`;

const NiceLink = styled.a`
  color: ${PRIMARY_COLOR};
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

const Copyright = styled.div``;

const TermsAndService = styled.a`
  color: ${PRIMARY_COLOR};
  text-decoration: none;
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;

const SupportEmail = styled.div``;
